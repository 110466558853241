@media (min-width: 1920px) {
    .logo {
        width: 225px;
        height: 286.75px;
    }

    .nav-link {
        font-weight: 700;
    }

    .nav-item {
        & .text-decoration-underline {
            text-underline-offset: 2px;
            text-decoration-thickness: 2px !important;
        }
    }

    .text-decoration-underline {
        text-underline-offset: 4px;
        text-decoration-thickness: 2px !important;
    }

    a {
        text-underline-offset: 4px;
        text-decoration-thickness: 2px !important;
        color: #7671ff;
    }

    .search-icon {
        width: 50px;
        height: 50px;

        & .search-icon-svg-color {
            stroke-width: 1.2px;
        }
    }

    .search-nav-item {
        margin-top: 13px
    }

    .offcanvas-body {
        & .search-field {
            font-size: 62px;
            letter-spacing: 0.62px;
            line-height: 86px;

            &::placeholder {
                font-size: 62px;
                letter-spacing: 0.62px;
                line-height: 86px;
            }
        }
    }

    .s16-ls16-lh28 {
        font-size: 24px;
        letter-spacing: 0.24px;
        line-height: 38px;
    }

    .social-icons-container {
        margin-top: 12px;
    }

    .social-icon {
        width: 30px;
        height: 30px;
    }

    .menu-close-icon-svg-color,
    .hamburger-icon-svg-color {
        stroke-width: 2px;
    }

    .scrollToTopContainer {    
        width: 100px;
        height: 100px;

        right: 40px;
        bottom: 40px;
    }

    .flow-text-padding-right {
        padding-right: 70px;
    }

    footer {
        // padding-top: 200px;

        & .footer-menu {
            & a {
                font-size: 24px;
                letter-spacing: 0.24px;
                line-height: 38px;

                font-weight: 700;
            }
        }
    }
}