.shevron-down-icon,
.shevron-up-icon,
.shevron-right-icon,
.shevron-left-icon {
    & .shevron-down-icon-svg-color {
        stroke-width: 2px;
    }
}

.shevron-up-icon {
    transform: rotate(180deg);
}

.shevron-right-icon {
    transform: rotate(-90deg);
}

.shevron-left-icon {
    transform: rotate(90deg);
}

.shevron-down-icon,
.shevron-up-icon,
.shevron-left-icon,
.shevron-right-icon {
    width: 60px;
    height: 30px;
}

.plus-icon,
.minus-icon {
    width: 60px;
    height: 60px;
}