@import "../mixins";

@media (min-width: 375px) and (max-width: 767px) {
    $minvw: 375px;
    $maxvw: 767px;

    .logo {
        @include fluid-property('width', 47px, 55px, $minvw, $maxvw);
        @include fluid-property('height', 59.9px, 70.1px, $minvw, $maxvw);
    }

    .nav-link {
        font-weight: 500;
    }

    .nav-item {
        & .text-decoration-underline {
            @include fluid-property('text-underline-offset', 2px, 2px, $minvw, $maxvw);
            @include fluid-property('text-decoration-thickness', 1px, 1px, $minvw, $maxvw);
        }
    }

    #offcanvas-mobile-menu {
        & .navbar-nav {
            @include fluid-property(padding-top, 61px, 102px, $minvw, $maxvw);
        }
    
        & .nav-item {
            @include fluid-property(font-size, 30px, 40px, $minvw, $maxvw);
            @include fluid-property(letter-spacing, 0.15px, 0.2px, $minvw, $maxvw);
            @include fluid-property(line-height, 46px, 60px, $minvw, $maxvw);
    
            & .text-decoration-underline {
                @include fluid-property(text-underline-offset, 4px, 4px, $minvw, $maxvw);
                @include fluid-property(text-decoration-thickness, 1px, 1px, $minvw, $maxvw, '!important');
            }
        }
    
        & .search-nav-item {
            margin-top: 0px !important;

            & .nav-link {
                @include fluid-property(margin-top, 8px, 11px, $minvw, $maxvw);
            }
        }
    
        & .offcanvas-close-icon {
            & .offcanvas-close-icon-svg-color {
                @include fluid-property(stroke-width, 0.5px, 0.5px, $minvw, $maxvw);
            }
        }
    
        & .search-icon {
            @include fluid-property(width, 46px, 75px, $minvw, $maxvw);
            @include fluid-property(height, 46px, 75px, $minvw, $maxvw);
        
            & .search-icon-svg-color {
                @include fluid-property(stroke-width, 0.8px, 0.65px, $minvw, $maxvw);
            }
        }
    }

    .text-decoration-underline {
        @include fluid-property('text-underline-offset', 2px, 3px, $minvw, $maxvw);
        @include fluid-property('text-decoration-thickness', 1px, 1px, $minvw, $maxvw, '!important');
    }

    a {
        @include fluid-property('text-underline-offset', 2px, 3px, $minvw, $maxvw);
        @include fluid-property('text-decoration-thickness', 1px, 1px, $minvw, $maxvw, '!important');
        color: #7671ff;
    }

    .search-icon {
        @include fluid-property('width', 46px, 75px, $minvw, $maxvw);
        @include fluid-property('height', 46px, 75px, $minvw, $maxvw);

        & .search-icon-svg-color {
            @include fluid-property('stroke-width', 1.8px, 1.2px, $minvw, $maxvw);
        }
    }

    .search-nav-item {
        @include fluid-property('margin-top', 8px, 8px, $minvw, $maxvw);
    }

    .offcanvas-body {
        & .search-field {
            @include fluid-property('font-size', 30px, 40px, $minvw, $maxvw);
            @include fluid-property('letter-spacing', 0.15px, 0.2px, $minvw, $maxvw);
            @include fluid-property('line-height', 46px, 60px, $minvw, $maxvw);

            &::placeholder {
                @include fluid-property('font-size', 30px, 40px, $minvw, $maxvw);
                @include fluid-property('letter-spacing', 0.15px, 0.2px, $minvw, $maxvw);
                @include fluid-property('line-height', 46px, 60px, $minvw, $maxvw);
            }
        }
    }

    // font formats
    .s16-ls16-lh28 {
        @include fluid-property('font-size', 16px, 16px, $minvw, $maxvw);
        @include fluid-property('letter-spacing', 0.16px, 0.16px, $minvw, $maxvw);
        @include fluid-property('line-height', 28px, 28px, $minvw, $maxvw);
    }

    .social-icons-container {
        @include fluid-property(margin-top, 14px, 14px, $minvw, $maxvw);
    }

    .social-icon {
        @include fluid-property('width', 30px, 30px, $minvw, $maxvw);
        @include fluid-property('height', 30px, 30px, $minvw, $maxvw);
    }

    .menu-close-icon-svg-color,
    .hamburger-icon-svg-color {
        @include fluid-property(stroke-width, 2px, 1.5px, $minvw, $maxvw);
    }

    .scrollToTopContainer {    
        @include fluid-property('width', 45px, 65px, $minvw, $maxvw);
        @include fluid-property('height', 45px, 65px, $minvw, $maxvw);

        @include fluid-property('right', 20px, 30px, $minvw, $maxvw);
        @include fluid-property('bottom', 20px, 30px, $minvw, $maxvw);
    }

    .shevron-down-icon,
    .shevron-up-icon {
        @include fluid-property('width', 24px, 30px, $minvw, $maxvw);
        @include fluid-property('height', 12px, 15px, $minvw, $maxvw);

        & .shevron-down-icon-svg-color {
            @include fluid-property('stroke-width', 1.2px, 1px, $minvw, $maxvw);
        }
    }

    .flow-text-padding-right {
        @include fluid-property('padding-right', 22.5px, 42.5px, $minvw, $maxvw);
    }

    footer {
        // @include fluid-property('padding-top', 75px, 100px, $minvw, $maxvw);

        & .footer-menu {
            & a {
                @include fluid-property('font-size', 16px, 16px, $minvw, $maxvw);
                @include fluid-property('letter-spacing', 0.16px, 0.16px, $minvw, $maxvw);
                @include fluid-property('line-height', 28px, 28px, $minvw, $maxvw);

                font-weight: 500;
            }
        }
    }
}