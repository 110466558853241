@import "../mixins";

.contact-snippet {
    right: 0px;
    top: 0px;

    width: 0px;

    & > .h-100.position-relative {
        width: 0px;
    }

    & .position-sticky {
        top: 35%;
        z-index: 3;
    }

    & .communication-link {

        transform: translateX(-100%);

        @include breakpoints(padding-right, 45px, 75px, 120px);

        &:first-child {
            @include breakpoints(margin-bottom, 20px, 30px, 40px);
        }

        & .send-mail-icon {
            @include breakpoints(width, 45px, 65px, 100px);
            @include breakpoints(height, 45px, 65px, 100px);

            & .send-mail-icon-svg-color {
                stroke-width: 2px;
            }
        }

        & .call-number-icon {
            @include breakpoints(width, 45px, 65px, 100px);
            @include breakpoints(height, 54px, 78px, 120px);

            & .call-number-icon-svg-color {
                stroke-width: 2px;
            }
        }
    }
}