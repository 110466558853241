#banner-content {
  width: 100%;
  height: 100%;
  z-index: 0;
  position: fixed;
  left: 0px;
  top: 0px;
  opacity: 0;
  transition: all 0.33s ease;
  .banner-background {
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
  }
  .banner-foreground {
    position: absolute;
    z-index: 2;
  }
  &.show {
    opacity: 1 !important;
    z-index: 2000;
  }
  .close-icon-container {
    position: absolute;
    right: 0;
    top: 0;
    padding-right: $grid-gap;
    padding-top: $grid-gap;
    svg {
        line {
            stroke-width: 1px;
        }
    }
  }
}
