%change-media-source {
    & .media-hover {
        opacity: 1;
        display: block !important;
    }
}

.person {
    height: 100%;

    color: $white-color !important;

    &.content-padding {
        padding: $grid-gap;
    }
    
    &:hover {
        & .media {
            @extend %change-media-source;
        }
    }

    .media {
        // & .media-start {
        //     transition: opacity 0.15s ease-in;
        // }

        // & .media-hover {
        //     transition: opacity 0.75s cubic-bezier(0.5, -0.5, 0.5, 1.5);
        // }

        & .media-hover {
            opacity: 0;
            display: none !important;
        }

        &:hover {
            @extend %change-media-source;
        }
    }

    & .person-data {
        height: 100%;
    }

    & .person-link {
        position: absolute;

        bottom: 0px;
    }

    & .plus-icon,
    & .minus-icon {
        @include breakpoints(width, 25px, 35px, 60px);
        @include breakpoints(height, 25px, 35px, 60px);
    }
}

.person-content {
    & .person-data {
        @include breakpoints(padding-top, 23px, 105px, 147px);

        @include breakpoints(padding-right, 22.5px, 42.5px, 70px);

        & .person_about,
        & .person_main_emphasis,
        & .person_interview {
            & > h2 {
                @include breakpoints(margin-left, 20px, 30px, 50px);
            }
        }

        & .interview_answer_text {
            & p { display: inline; }

            // &::before {
            //     content:'\201E';
            //     display: inline;

            //     @include breakpoints(margin-right, -6px, -8px, -10px);
            // }

            // &::after {
            //     content: '\201C';
            //     display: inline;

            //     @include breakpoints(margin-left, -6px, -8px, -10px);
            // }
        }

        & .back-action {
            @include breakpoints(margin-top, 30px, 36px, 64px);

            & .shevron-left-icon {
                @include breakpoints(width, 20px, 30px, 40px);
                @include breakpoints(height, 10px, 15px, 20px);

                @include breakpoints(margin-left, -6px, -9px, -10px);

                @include breakpoints(margin-right, 15px, 15px, 30px);
            }
        }
    }
}

