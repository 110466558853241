.file-download {
    & .grid {
        grid-template: 'main';

        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);

        @media (min-width: 768px) and (max-width: 991px) {

            grid-template-columns: 1fr;
            grid-template-rows: repeat(2, 1fr) !important;

            .tablet-cxf {
                grid-row: initial;

                &::before { display: none !important; }
                & .content { position: relative !important; }
            }
        }

        @media (max-width: 767px) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: initial !important;

            & .cx1 {
                &.cy1 {
                    &::before { display: none !important; }
                }
            }

            & .secondary-link-container {
                & .content {
                    height: 100%;
                }
            }
        }

        & a {
            text-decoration: none;

            width: 100%;
            height: 100%;

            overflow: hidden;
            
            &.primary-link {
                display: block;

                @include breakpoints(padding, 10px, (20px 10px), 20px);
            }

            &.secondary-link {
                display: flex;
                align-items: flex-end;

                & .upload-button-icon {
                    @include breakpoints(width, 74px, 120px, 229px);
                    @include breakpoints(height, 74px, 120px, 229px);
            
                    transform: rotate(180deg);
                }
            }

            & .title {
                text-transform: uppercase;

                @include breakpoints(font-size, 12px, 16px, 40px);
                @include breakpoints(letter-spacing, 0.48px, 0.72px, 1.8px);
                @include breakpoints(line-height, 18px, 26px, 60px);

                @include breakpoints-transform(translateY, 0px - (18px - 12px) / 2, 0px - (26px - 16px) / 2, 0px - (60px - 40px) / 2);
            }

            & .place-fs24 {
                @include breakpoints(font-size, 12px, 16px, 26px);
                @include breakpoints(letter-spacing, 0.12px, 0.16px, 0.26px);
                @include breakpoints(line-height, 18px, 26px, 42px);
            }

            & .text {
                @include breakpoints(font-size, 12px, 16px, 26px);
                @include breakpoints(letter-spacing, 0.12px, 0.16px, 0.26px);
                @include breakpoints(line-height, 18px, 26px, 42px);
            }

            & .image-container {
                display: block;

                width: 100%;
                height: 100%;

                position: absolute;
                top: 0px;
                left: 0px;

                & .first-upload-icon {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}