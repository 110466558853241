.form-check-input {
    &[type="checkbox"] {
      -webkit-appearance: none;

      vertical-align: middle;
      position: relative;

      width: 25px;
      height: 25px;

      margin-top: 0rem;
      margin-right: 0.5rem;

      border-style: solid;
      border-radius: 0px;

      cursor: pointer;

      &:active,
      &:focus {
        box-shadow: none;
        filter: brightness(100%);
      }

      &:checked {

        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath fill='none' stroke='%23ffffff' stroke-width='1.75' d='M3.76,14.76l8.82,8.82 l14.65-17.65'/%3e%3c/svg%3e");
      }
    }
  }

  .matomo .form-check-input {
    &[type="checkbox"] {      
      border-color: $optional-color;


      &:checked {
        border-color: $optional-color;
        background-color: $optional-color;
      }
    }
  }