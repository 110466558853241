@media (max-width: 374px) {
    .logo {
        width: 47px;
        height: 59.9px;
    }

    .nav-link {
        font-weight: 500;
    }

    .nav-item {
        & .text-decoration-underline {
            text-underline-offset: 2px;
            text-decoration-thickness: 1px !important;
        }
    }

    #offcanvas-mobile-menu {
        & .navbar-nav {
            padding-top: 61px;
        }
    
        & .nav-item {
            font-size: 30px;
            letter-spacing: 0.15px;
            line-height: 46px;
    
            & .text-decoration-underline {
                text-underline-offset: 4px;
                text-decoration-thickness: 1px !important;
            }
        }
    
        & .search-nav-item {
            margin-top: 0px !important;

            & .nav-link {
                margin-top: 8px;
            }
        }
    
        & .offcanvas-close-icon {
            & .offcanvas-close-icon-svg-color {
                stroke-width: 0.5px;
            }
        }
    
        & .search-icon {
            width: 46px;
            height: 46px;
        
            & .search-icon-svg-color {
                stroke-width: 0.8px;
            }
        }
    }

    .text-decoration-underline {
        text-underline-offset: 2px;
        text-decoration-thickness: 1px !important;
    }

    a {
        text-underline-offset: 2px;
        text-decoration-thickness: 1px !important;
        color: #7671ff;
    }

    .search-icon {
        width: 40px;
        height: 40px;

        & .search-icon-svg-color {
            stroke-width: 1.5px;
        }
    }

    .search-nav-item {
        margin-top: 8px
    }

    .offcanvas-body {
        & .search-field {
            font-size: 30px;
            letter-spacing: 0.15px;
            line-height: 46px;

            &::placeholder {
                font-size: 30px;
                letter-spacing: 0.15px;
                line-height: 46px;
            }
        }
    }

    // font formats
    .s16-ls16-lh28 {
        font-size: 16px;
        letter-spacing: 0.16px;
        line-height: 28px;
    }

    .social-icons-container {
        margin-top: 14px;
    }

    .social-icon {
        width: 30px;
        height: 30px;
    }

    .menu-close-icon-svg-color,
    .hamburger-icon-svg-color {
        stroke-width: 2px;
    }

    .scrollToTopContainer {    
        width: 45px;
        height: 45px;

        right: 20px;
        bottom: 20px;
    }

    .shevron-down-icon,
    .shevron-up-icon {
        width: 24px;
        height: 12px;

        & .shevron-down-icon-svg-color {
            stroke-width: 1.2px;
        }
    }

    .flow-text-padding-right {
        padding-right: 22.5px;
    }

    footer {
        // padding-top: 75px;

        & .footer-menu {
            & a {
                font-size: 16px;
                letter-spacing: 0.16px;
                line-height: 28px;

                font-weight: 500;
            }
        }
    }
}