.quote-container {
    grid-row: span 2;
    @include before-for-square-block(50%, $grid-gap / 2);

    position: relative;

    & .quote-block {
        height: 100%;

        display: grid;
        grid-template-columns: 1fr calc(50% - $grid-gap / 2) 1fr;
        
        & .quote {
            display: grid;
            grid-template-rows: 1fr max-content 1fr;
    
            line-height: 1.5;
    
            height: 100%;
    
            & .text {
                grid-row: 2 / 3;
                text-align: center;
            }
    
            & .author {
                grid-row: 3 / 4;
                text-align: center;
    
                & a {
                    color: $third-color;
        
                    &:active,
                    &:focus,
                    &:hover {
                        @extend a;
                    }
                }
            }
        }
    }

}

@media (min-width: 768px) and (max-width: 991px) {
    .quote-container {
        @include before-for-square-block(100% / 3 * 2, $grid-gap / 2);

        & .quote-block {
            grid-template-columns: 1fr calc(100% / 3 * 2 - $grid-gap / 2) 1fr;
        }
    }
}

@media (max-width: 767px) {
    .quote-container {
        @include before-for-square-block(100%, 0px);

        & .quote-block {
            grid-template-columns: 1fr 100% 1fr;
        }

        & .quote {
            display: flex;
            flex-direction: column;
            justify-content: center;

            padding-left: $grid-gap;
            padding-right: $grid-gap;
        }
    }
}