@use 'sass:math';
@use "sass:map";
@use "sass:list";

@mixin hover-supported {    
    /* 
     * https://developer.mozilla.org/en-US/docs/Web/CSS/@media/pointer 
     * coarse: The primary input mechanism includes a pointing device of limited accuracy.
     */
    @media not all and (pointer: coarse) {
        &:hover {
            @content;
        }
    }
}

@function encodecolor($string) {
    @if type-of($string) == 'color' and str-index(#{$string}, '#') == 1 {
      $hex: str-slice(ie-hex-str($string), 4);
      $string: unquote('#{$hex}');
  
      @return '%23' + $string;
    }
  
    @return $string;
}

@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

@mixin fluid-property($property, $minsize, $maxsize, $minvw, $maxvw, $important:'') {

    & {
        #{$property}: calc(#{$minsize} + (#{strip-unit($maxsize - $minsize)} * ( (100vw - #{$minvw}) / #{strip-unit($maxvw - $minvw)} )) ) #{$important};
    }
}

@mixin scrollbars(
  $minsize,
  $foreground-color,
  $background-color: mix($foreground-color, white, 50%),
  $responsive: ()
) {
  // For Google Chrome

  @if (null != map.get($responsive, 'maxsize')) {
    &::-webkit-scrollbar {
        @include fluid-property('width', $minsize, map.get($responsive, 'maxsize'), map.get($responsive, 'minvw'), map.get($responsive, 'maxvw'));
        @include fluid-property('height', $minsize, map.get($responsive, 'maxsize'), map.get($responsive, 'minvw'), map.get($responsive, 'maxvw'));
    }
  } @else {
    &::-webkit-scrollbar {
        width: $minsize;
        height: $minsize;
    }
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@mixin breakpoints($property, $mdim, $tdim, $ddim, $important:'', $explizit: false) {
  $mobile-width: 375px;
  $tablet-width: 768px;
  $desktop-width: 1920px;

  & {
    @media (max-width: ($mobile-width - 1px)) {
      #{$property}: $mdim #{$important};
    }

    @media (min-width: $mobile-width) and (max-width: ($tablet-width - 1px)) {
      @if $explizit {
        #{$property}: $mdim #{$important};
      } @else {
        $firsttdim: list.nth($tdim, 1);
        @include fluid-property($property, $mdim, $firsttdim, $mobile-width, $tablet-width, $important);
      }
    }

    @if $ddim != '' {

      @media (min-width: $tablet-width) and (max-width: ($desktop-width - 1px)) {
        $firsttdim: $tdim;

        @if list.length($tdim) > 1 {
          $firsttdim: list.nth($tdim, 2);
          @debug list.nth($tdim, 2);
        }

        @if ($firsttdim != '') {
          @if $explizit {
            #{$property}: $firsttdim #{$important};
          } @else {
            @include fluid-property($property, $firsttdim, $ddim, $tablet-width, $desktop-width, $important);
          }
        }

      }

    
      @media (min-width: $desktop-width) {
        #{$property}: $ddim #{$important};
      }
    }
  }
}

@mixin fluid-property-transform($property, $minsize, $maxsize, $minvw, $maxvw, $important:'') {

  & {
    transform: #{$property}(calc(#{$minsize} + (#{strip-unit($maxsize - $minsize)} * ( (100vw - #{$minvw}) / #{strip-unit($maxvw - $minvw)} )) ) #{$important});
  }
}

@mixin breakpoints-transform($property, $mdim, $tdim, $ddim, $important:'', $explizit: false) {
  $mobile-width: 375px;
  $tablet-width: 768px;
  $desktop-width: 1920px;

  & {
    @media (max-width: ($mobile-width - 1px)) {
      transform: #{$property}($mdim) #{$important};
    }

    @media (min-width: $mobile-width) and (max-width: ($tablet-width - 1px)) {
      @if $explizit {
        transform: #{$property}($mdim) #{$important};
      } @else {
        $firsttdim: list.nth($tdim, 1);
        @include fluid-property-transform($property, $mdim, $firsttdim, $mobile-width, $tablet-width, $important);
      }
    }

    @if $ddim != '' {

      @media (min-width: $tablet-width) and (max-width: ($desktop-width - 1px)) {
        $firsttdim: $tdim;

        @if list.length($tdim) > 1 {
          $firsttdim: list.nth($tdim, 2);
          @debug list.nth($tdim, 2);
        }

        @if ($firsttdim != '') {
          @if $explizit {
            transform: #{$property}($firsttdim) #{$important};
          } @else {
            @include fluid-property-transform($property, $firsttdim, $ddim, $tablet-width, $desktop-width, $important);
          }
        }

      }

    
      @media (min-width: $desktop-width) {
        transform: #{$property}($ddim) #{$important};
      }
    }
  }
}

@mixin before-for-square-block($padding-percent: '', $diff: 0px) {
  $pb: 100%;

  @if ('' != $padding-percent) {
    $pb: $padding-percent;
  }

  @if (0px != $diff) {
    $pb: calc($padding-percent - $diff);
  }

  & {
    line-height: 0;
    position: relative;

    &::before {
      content: "";
      display: inline-block;
      width: 1px;
      height: 0px;
      padding-bottom: $pb;
    }

    & > .content {
      line-height: 1.5;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }
  }
}