@import "fluidtypography";
@import "./mixins";
@import "./colors";

:root {
  --width-percentage: 0%;
}

a,
a:active,
a:hover,
a:focus {
  cursor: pointer !important;
  color: $third-color;
  text-underline-offset: 3px !important;
  outline: none !important;
  @include breakpoints(text-decoration-thickness, 1px, 1px, 2px, !important);
}

a:active,
a:hover,
a:focus {
  color: $third-color !important;
}

a:visited,
a:active {
  outline: none !important;
}

header {
  // grid-column: 1/2;
  // grid-row: 1/3;
  grid-area: hd;

  & .nav-link {
    color: $initial-optional-color;

    &:hover,
    &:focus,
    &.active {
      color: $third-color;

      .search-icon-svg-color {
        stroke: $third-color !important;
      }
    }
    &.highlightedLink {
      color: #871631;
    }
  }
}

.search-icon {
  & .search-icon-svg-color {
    stroke: $initial-optional-color;
  }
}

nav {
  & .nav-link {
    color: $initial-optional-color;

    &:hover,
    &:focus,
    &.active {
      color: $black-color;
      .search-icon-svg-color {
        stroke: $black-color !important;
      }
    }

  }
}

footer {
  .nav-link {
    color: $initial-optional-color;

    &:hover,
    &:focus,
    &.active {
      color: $third-color !important;
    }
  }
  .social-icons-container > a {
    &:hover,
    &:focus,
    &.active {
      .iconContainer.social-icon {
        background-color: $third-color !important;
      }
    }
  }
}

.menu-block-margin-top {
  margin-top: 20px;
}

.mobile-navi {
  background-color: $white-color;

  align-items: start;

  & .navbar-nav {
    margin-top: -7px;
  }

  &.hide-by-offcanvas {
    z-index: 1 !important;
    opacity: 0;
  }
}

.fullheight-min {
  min-height: 100vh;
  grid-template-rows: min-content min-content !important;
}

.min-fullheight {
  grid-template-rows: min-content min-content 1fr !important;
}

.hamburger-icon {
  width: 35.34px;
  height: 27px;

  & .hamburger-icon-svg-color {
    stroke: $third-color;
  }
}

.offcanvas-close-icon,
.menu-close-icon {
  width: 25px;
  height: 25px;

  & svg {
    vertical-align: top;
  }
}

nav {
  & .navbar {
    & button {
      &[aria-expanded="false"] {
        & .menu-close-icon {
          display: none !important;
        }
      }

      &[aria-expanded="true"] {
        & .hamburger-icon {
          display: none !important;
        }
      }
    }
  }
}

#offcanvas-mobile-menu {
  background-color: rgba(255, 255, 255, 0.96);

  & .offcanvas-close-icon-svg-color {
    stroke: $third-color;
  }

  & .nav-link {
    color: $optional-color;
    font-weight: 500;

    &:hover,
    &:focus,
    &.active {
      color: $third-color;
      .search-icon-svg-color {
        stroke: $third-color !important;
      }
    }
    &.highlightedLink {
      color: #871631;
    }
  }

  .search-icon {
    & .search-icon-svg-color {
      stroke: $optional-color;
    }
  }
}

.search-field {
  color: $optional-color;

  &:focus,
  &:hover {
    color: $optional-color;
  }

  &::placeholder {
    color: $initial-optional-color !important;
  }

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
}

.scrollToTopContainer {
  display: none;

  position: fixed;

  z-index: 3;

  width: 100px;
  height: 100px;

  align-items: center;
  justify-content: center;

  border: none;

  & #scrollToTop {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    & .shevron-up-icon {
      transform: rotate(180deg);

      & .shevron-down-icon-svg-color {
        stroke: $third-color;
      }
    }
  }
}

.content {
  &.overflow-init {
    overflow: visible;

    & .position-sticky {
      top: 20px;
      width: fit-content;
    }
  }
}

.offcanvas-full {
  top: 0;
  left: 0;
  width: 100%;
}

.iconContainer.social-icon {
  background-color: $initial-optional-color !important;
}

.offcanvas {
  & .offcanvas-close-icon-svg-color {
    stroke-width: 1px;
  }
}

.form-control,
.form-control:focus {
  padding: 0px !important;

  border: none !important;

  background: none;
  box-shadow: none;

  border-radius: 0px;

  font-weight: 500;

  &::placeholder {
    color: $initial-optional-color;
    font-weight: 400;
  }
}

.btn:focus {
  box-shadow: none;
}

.offcanvas-body,
.modal-body {
  padding: 20px;
}

$anthracite-color: #37373c;
$red-color: #871631;

$gray-color: #dedede;
$gray-1-color: #d8dadb;
$gray-2-color: #aaabb5;

$blau-1-color: #7574f6;

$beige-color: #f6f3f3;
$coral-color: #fa8294;
$white-color: #ffffff;

$blue-color: #7671ff;

.anthracite {
  background-color: $anthracite-color;

  & * {
    color: $white-color;
  }

  & .link-content {
    color: $gray-2-color;

    & a {
      color: $gray-2-color;
      //mini-anpassung mail 
      //color: $white-color;

      &:active,
      &:focus,
      &:hover {
        color: $gray-2-color;
      }
    }
  }
}
.red {
  background-color: $red-color;

  & * {
    color: $white-color;
  }
}

.gray {
  background-color: $gray-color;
}
.gray-1 {
  background-color: $gray-1-color;
}

.beige {
  background-color: $beige-color;
}
.coral {
  background-color: $coral-color;
}
.white {
  background-color: $white-color;
}

.blue {
  color: $blue-color;
}

.img {
  background-color: #ffe6cc;
}

.placeholder {
  background-color: transparent;
}

.inherit-background-color {
  & div {
    background-color: inherit;
  }
}

.cadetblue {
  background-color: cadetblue;
}
.darkgray {
  background-color: darkgray;
}

.lightgray {
  background-color: lightgray;
}

.dark {
  background-color: #647687;
}

.list-group {
  & li {
    display: flex;
    flex-direction: row;

    &::before {
      content: "•";
      display: flex;

      width: 20px;
      min-width: 20px;
    }

    & .list-group {
      padding-left: 20px;
    }
  }
}

p {
  padding: 0px;
  margin: 0px;
}

.w-100-20 {
  width: calc(100% + 20px);

  height: calc(100% + 40px) !important;

  margin-top: -20px;
}

.fix-collapse-margin {
  padding-top: 0.1px;
}

.menu-height-placeholder {
  @include breakpoints(padding-bottom, 60px, 70px, 78px);
}

.close-icon-container {
  z-index: 1;
  padding-left: 20px !important;
  padding-bottom: 20px !important;
}

.cx1-cy1-scrollable {
  @media (min-width: 992px) and (max-width: 1484px) {
    & .content {
      padding-right: 0px !important;

      & > div:first-child {
        @include scrollbars(5px, #7574f6, transparent, ("maxsize": 8px, "minvw": 768px,"maxvw": 1920px));
        overflow-y: auto !important;
      }
    }
  }
}

.placeholder {
  cursor: inherit !important;
}
// styled-scrollbar
@media (min-width: 1920px) { 
  .styled-scrollbar {
      @include scrollbars(8px, #7574f6, transparent, ());
  }
}

@media (min-width: 768px) and (max-width: 1919px) {
  $minvw: 768px;
  $maxvw: 1920px;

  .styled-scrollbar {
      @include scrollbars(5px, #7574f6, transparent, ("maxsize": 8px, "minvw": $minvw,"maxvw": $maxvw));
  }

}  

@media (min-width: 375px) and (max-width: 767px) {
  $minvw: 375px;
  $maxvw: 767px;

  .styled-scrollbar {
      @include scrollbars(4px, #7574f6,transparent, ("maxsize": 5px, "minvw": $minvw, "maxvw": $maxvw));
  }
}

@media (max-width: 374px) {
  .styled-scrollbar {
      @include scrollbars(4px, #7574f6, transparent, ());
  }
}

@import "./site/grid-responsive";

@import "./site/icons";
@import "./site/fonts-formats";

@import "./site/contact-snippet";
@import "./site/contact_snippet_offcanvas";

@import "./site/quote-content";
@import "./site/link-content";
@import "./site/list-content";
@import "./site/slider-content";
@import "./site/tabs-content";
@import "./site/carousel-content";
@import "./site/fade-content";
@import "./site/person-content";
@import "./site/chapter-content";
@import "./site/manifest-content";
@import "./site/footer";
@import "./site/blog";
@import "./site/contact";
@import "./site/inprint";
@import "./site/banner";
@import "./site/file-download";
@import "./site/scrollable-content";

@import "./site/small";
@import "./site/mobile-tablet-resposive";
@import "./site/tablet-desktop-resposive";
@import "./site/large";

@import "./site/website-body-media-tablet";
