@import "../mixins";

.footer-margin-top {
  @include breakpoints(margin-top, 90px, 110px, 240px);
}

footer {
  padding-left: 0px !important;
  padding-right: 0px !important;

  & .footer-menu {
    & a {
      color: $initial-optional-color !important;
    }
  }

  & .margin-social-icon-to-next {
    margin-right: 20px;
  }
  & .footer-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: $grid-gap;
    @media (min-width: 768px) and (max-width: 991px) {
      grid-template-columns: repeat(3, 1fr);
      & .cx3 {
        grid-column: span 2;
      }
    }

    @media (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
      & .cx3 {
        grid-column: span 1 !important;
      }
    }
    .footer-text {
      color:$gray-1-color;
    }
  }
}
