.manifest-content {
    @include before-for-square-block(50%, $grid-gap / 2);

    grid-column: span 2;
    grid-row: span 2;

    & .manifest-block {
        height: 100%;
        aspect-ratio:  1 / 1;
        width: auto;

        margin-left: auto;
        margin-right: auto;

        // border: 1px solid green;
    }

    & .carousel {
        height: 100%;

        & .carousel-inner {
            height: 100%;

            & .carousel-item {
                height: 100%;

                & .carousel-item-container {
                    height: 100%;

                    & .carousel-item-content {
                        height: 100%;
                        display: grid;
                        grid-template-rows: 1fr max-content 1fr;

                        & .title {
                            text-align: center;
                            grid-row: 1 / 2;

                            & .title-content {
                                @include breakpoints(margin-top, 4px, 38px, 117px);
                            }
                        }

                        & .text {
                            text-align: center;
                            grid-row: 2 / 3;
                        }
                    }

                }
            }
        }
    }
}

@mixin tablet-fw-ah-size {
    & {
        grid-column-start: 1;
        grid-column-end: -1;

        grid-row: auto;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .manifest-content {
        @include before-for-square-block(100% / 3 * 2, $grid-gap / 2);
        @include tablet-fw-ah-size;
    }
}

@media (max-width: 767px) {
    .manifest-content {
        @include before-for-square-block(100%, 0px);
        @include tablet-fw-ah-size;
    }
}