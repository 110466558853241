@import "../mixins";
// GRID BASIS
$grid-gap: 20px;

.grid {
  display: grid;
  grid-template:
    "hd main main main" min-content
    "hd main main main" min-content
    "aside main main main" 1fr;

  grid-gap: $grid-gap;
  padding: 20px 20px 0px 20px;

  grid-template-columns: repeat(4, 1fr);
}

.grid-middleblock {
  display: grid;
  grid-gap: $grid-gap;

  grid-template-columns: repeat(3, 1fr);
}

.cx1 {
  grid-column: span 1;
}

.cx2 {
  grid-column: span 2;
}

.cx3 {
  grid-column: span 3;
}

.cxf {
  grid-column-start: 1;
  grid-column-end: -1;
}

.cy1 {
  grid-row: span 1;
}

.cy2 {
  grid-row: span 2;
}

.cy3 {
  grid-row: span 3;
}

.cx1 {
  &.cy1 {
    @include before-for-square-block();
  }
}

.cx2 {
  &.cy1 {
    @include before-for-square-block(50%, $grid-gap / 2);
  }

  &.cy2 {
    @include before-for-square-block();
  }
}

.cx3 {
  &.cy3 {
    @include before-for-square-block();
  }
}

.max-width-2-3 {
  &:not(.extra-thin) {
    width: 100%;
    display: flex;
  
    @include breakpoints(padding-right, 25px, 55px, 0px);
  
    @media (min-width: 768px) and (max-width: 1919px) {
      &::after {
        content: '';
        display: block;
        padding-right: calc(var(--width-percentage) - 20px);
      }    
  
    }
  
    @media (min-width: 1920px) {
      width: calc(75% + #{20px});
    }
  }
}

.padding-for-icons {
    width: 100%;
    display: flex;
  
    @include breakpoints(padding-right, 2.5px, 22.5px, 50px);

    &.plus-10 {
      @include breakpoints(padding-right, 12.5px, 32.5px, 60px);
    }

    &.plus-20 {
      @include breakpoints(padding-right, 22.5px, 42.5px, 70px);
    }
}

.content {
  position: relative;

  &.content_padding {
    padding: $grid-gap;

    &.block-fade_content {
      @media (max-width: 768px) {
        @include breakpoints(padding, $grid-gap / 2, $grid-gap, 0px);
      }
    }
  }
}

.only-desktop,
.only-tablet {
  display: none;
}

.mobile-navi {
  top: $grid-gap;

  padding-bottom: $grid-gap;

  & .navbar-content {
    padding-left: $grid-gap;
    padding-right: $grid-gap;
  }
  & .hidden-top-place {
    height: $grid-gap;
    top: -$grid-gap;
    background-color: inherit;
  }
}

@media (min-width: 992px) {
  .only-desktop {
    display: block;
  }
}

.cx2.cy1.cx1-tablet.cy2-tablet {
  grid-row: span 2;
}

@media (min-width: 768px) and (max-width: 991px) {
  .grid {
    grid-template: "main main main";
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: initial !important;
    padding-bottom: 20px;
  }

  .cx1 {
    &:not(.tablet-keep-size):not(.tablet-keep-x-size) {
      grid-column: span 2;
    }
  }

  .cx1-tablet {
    grid-column: span 1 !important;
  }

  .cxf-tablet {
    grid-column-start: 1;
    grid-column-end: -1;
  }

  .cy1 {
    &:not(.tablet-keep-size):not(.tablet-keep-y-size) {
      grid-row: span 2;
    }
  }

  .cy2-tablet {
    grid-row: span 2 !important;
  }

  .only-tablet {
    display: block;
  }

}

@media (max-width: 767px) {
  .grid {
    grid-template: "main main";
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr !important;

    padding-bottom: 20px;
  }

  .cx1,
  .cx2,
  .cx3 {
    &:not(.mobile-keep-size):not(.mobile-keep-x-size) {
      grid-column-start: 1;
      grid-column-end: -1;
    }
  }

  .cy2-mobile {
    grid-row: span 2 !important;
  }

  .cx2-mobile {
    grid-column: span 2 !important;
  }

  .cx1 {
    &.cy2 {
      @include before-for-square-block();
    }
  }

  .mobile-min-square {
    &::before { display: none !important; }
    & .content {
      position: relative !important;
      display: flex;

      @include before-for-square-block();

      &::before {
        flex-shrink: 1;
      }
    }
  }

  .mobile-cxf {
    grid-row: auto;

    &::before { display: none !important; }
    & .content { position: relative !important; }
  }

  .extra-margin-top-mobile {
    margin-top: 24px;
  }

  .extra-margin-bottom-mobile {
    margin-bottom: 19px;
  }

  .desktop-tablet {
    display: none;
  }

}