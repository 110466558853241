@import "../mixins";

.slider {
    grid-column: 1 / -1;

    & .slider-grid {
        display: grid;

        grid-template-columns: repeat(4, 1fr);
        grid-gap: $grid-gap;

        @media (min-width: 768px) and (max-width: 991px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width: 767px) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    & .carousel {
        .carousel-item.active,
        .carousel-item-next,
        .carousel-item-prev {
            display: inline-block;
        }

        & .active {
            display: inline-block;
            &.carousel-item-start {
                margin-left: -100%;
            }
        }

        & .carousel-item-next {
            &.carousel-item-start {
                & + .carousel-item {
                    // @media (min-width: 768px) {
                    //     display: inline-block;
                    // }
                }
            }
        }
    
        & .carousel-item-prev {
            &:not(.carousel-item-end) {
                margin-left: -100%;

                & + .carousel-item {
                    display: inline-block;
                }
            }
        }

        & .carousel-inner {
            background: none;

            white-space: nowrap;
            overflow: hidden !important;

            // TODO: temporar
            color: white;
        }

        & .carousel-item {
            margin-right: 0px;

            float: none;

            width: 100%;
            height: 100%;

            transform: none;

            transition-property: margin-left;

            vertical-align: top;

            & .item-content {
                white-space: initial;

                display: grid;
                grid-template-rows: min-content min-content 1fr;
                height: 100%;

                padding: $grid-gap;
            }

            & p {
                margin-bottom: 0px;
                margin-block: 0px;
            }

            & .index {
            }
            & .title {

                // &.fs60 {
                //     @media (min-width: 992px) and (max-width: 1473px) {
                //         @include fluid-property(font-size, 40px, 52px, 992px, 1473px);
                //         @include fluid-property(letter-spacing, 0.4px, 0.52px, 992px, 1473px);
                //         @include fluid-property(line-height, 60px, 72px, 992px, 1473px);
                //     }
                // }
            }
            & .text {
                height: 100%;
                overflow-y: auto;

                & p {
                    color: $gray-2-color;
                }
            }

            &.active {
                & + .carousel-item {
                    @media (min-width: 768px) {
                        display: inline-block;
                    }
                }
            }
        }

        & .carouselControls {
            position: relative;

            @include breakpoints(height, 60px, 123px, 191px);

            display: grid;
            grid-gap: 20px;
            grid-template-columns: repeat(3, 1fr);

            & .carousel-control-prev {
                grid-column: 1 / 2;

                align-items: center;
                justify-content: flex-start;
                @include breakpoints(padding-left, 45px, 68px + $grid-gap/2, 105px + $grid-gap);

            }

            & .carousel-control-next {
                grid-column: 3 / 4;

                align-items: center;
                justify-content: flex-end;
                @include breakpoints(padding-right, 45px, 68px + $grid-gap/2, 105px + $grid-gap);

            }

            & .carousel-control-prev,
            & .carousel-control-next {
                position: relative;

                width: 100%;
                height: 100%;

                opacity: 1;
                cursor: pointer;
            }

            & .shevron-right-icon,
            & .shevron-left-icon {

                @include breakpoints(width, 24px, 35px, 60px);
                @include breakpoints(height, 12px, 15px, 30px);

                & .shevron-down-icon-svg-color {
                    stroke: $blau-1-color;
                }
            }
        }

        & .separator {
            @include breakpoints(height, 3px, 3px, 3px);
            background-color: $gray-2-color;
        }

    }
}