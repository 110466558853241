@import "../mixins";

.contact_snippet_offcanvas {
    display: flex;
    flex-direction: row;

    & .separator {
        width: 40px;

        text-align: center;

        &::before {
            content: '';
            display: inline-block;

            @include breakpoints(width, 1px, 1px, 2px);

            height: 100%;
            background-color: $third-color;
        }
    }

    & .communication-link {

        & .send-mail-icon {
            width: 35px;
            height: 35px;

            & .send-mail-icon-svg-color {
                @include breakpoints(stroke-width, 2px, 2px, 2px);
            }
        }

        & .call-number-icon {
            width: 35px;
            height: 35px;

            & .call-number-icon-svg-color {
                @include breakpoints(stroke-width, 2px, 2px, 2px);
            }
        }
    }
}