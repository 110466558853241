@import "../mixins";

.chapter-content {
  @include breakpoints(margin-top, 13px, 23px, 37px);
  @include breakpoints(margin-bottom, 22px, 32px, 49px);

  & .chapter {
    display: flex;
    width: 100%;

    font-size: 24px;
    line-height: 36px;

    align-items: baseline;

    & .text {
      flex-shrink: 1 !important;
      margin-right: 20px;

      color: $gray-1-color;
      font-weight: 700;

      white-space: nowrap;

      &:empty {
        margin-right: 0px;

        &:before {
          content: "";
          display: inline-block;

          font-size: inherit;
          line-height: inherit;
        }
      }
    }

    & .divider {
      width: 100%;

      @include breakpoints(height, 1px, 1px, 2px);
      background-color: $gray-1-color;
    }

    &-blue {
      .text {
        color: $blue-color !important;
      }
      .divider {
        background-color: $blue-color !important;
      }
    }
  }
}
