@import "../mixins";

.medium {
    font-weight: 500 !important;
}

.regular {
    font-weight: 400 !important;
}

.fs60 {
    @include breakpoints(font-size, 24px, 40px, 60px);
    @include breakpoints(letter-spacing, 0.24px, 0.4px, 0.6px);
    @include breakpoints(line-height, 36px, 60px, 80px);

    &:first-child:not(.skip-margin-top),
    &.first-child:not(.skip-margin-top) {
        @include breakpoints-transform(translateY, 0px - (36px - 24px) / 2, 0px - (60px - 40px) / 2, 0px - (80px - 60px) / 2);
        @include breakpoints(margin-bottom, 0px - (36px - 24px) / 2, 0px - (60px - 40px) / 2, 0px - (80px - 60px) / 2);
    }

    &.number {
        letter-spacing: 0px;
    }
}

.fs40 {
    @include breakpoints(font-size, 24px, 24px, 40px);
    @include breakpoints(letter-spacing, 0.24px, 0.24px, 0.4px);
    @include breakpoints(line-height, 36px, 36px, 60px);

    &.uppercase,
    & .uppercase {
        text-transform: uppercase;
        @include breakpoints(letter-spacing, 1.08px, 1.08px, 1.8px);
    }

    &:first-child:not(.skip-margin-top),
    &.first-child:not(.skip-margin-top) {
        @include breakpoints-transform(translateY, 0px - (36px - 24px) / 2, 0px - (36px - 24px) / 2, 0px - (60px - 40px) / 2);
        @include breakpoints(margin-bottom, 0px - (36px - 24px) / 2, 0px - (36px - 24px) / 2, 0px - (60px - 40px) / 2);
    }

    &.equals-padding-bottom  {
        @include breakpoints(padding-bottom,(36px - 24px) / 2, (36px - 24px) / 2, (60px - 40px) / 2);
    }

    &.fs30-tablet {
        @media (min-width: 768px) and (max-width: 1919px) {
            font-size: 30px;
            letter-spacing: 0.3px;
            line-height: 46px;

            &.uppercase,
            & .uppercase {
                letter-spacing: 1.35px;
            }

            &:first-child:not(.skip-margin-top),
            &.first-child:not(.skip-margin-top) {
                transform: translateY(0px - (46px - 30px) / 2);
                margin-bottom: 0px - (46px - 30px) / 2;
            }
        }
    }

    &.fs16-mobile {
        @media (max-width: 767px) {
            @include breakpoints(font-size, 16px, 24px, 0px);
            @include breakpoints(letter-spacing, 0.16px, 0.24px, 0px);
            @include breakpoints(line-height, 26px, 36px, 0px);

            &.uppercase,
            & .uppercase {
                @include breakpoints(letter-spacing, 0.72px, 1.08px, 1.8px);
            }

            &:first-child:not(.skip-margin-top),
            &.first-child:not(.skip-margin-top) {
                @include breakpoints-transform(translateY, 0px - (26px - 16px) / 2, 0px - (36px - 24px) / 2, 0px);
                @include breakpoints(margin-bottom, 0px - (26px - 16px) / 2, 0px - (36px - 24px) / 2, 0px);
            }
        }
    }
}

@mixin webkit-box {
    & {
        overflow: hidden;
        -webkit-box-orient: vertical;
        display: -webkit-box !important;
    }
}

.line-clamp {
    @media (max-width: 767px) {
        @include webkit-box;
        -webkit-line-clamp: 4;
    }

    @media (max-width: 374px) {
        @include webkit-box;
        -webkit-line-clamp: 2;
    }
}


.fs30 {
    @include breakpoints(font-size, 24px, 30px, 30px);
    @include breakpoints(letter-spacing, 0.24px, 0.3px, 0.3px);
    @include breakpoints(line-height, 36px, 46px, 46px);

    &:not(.keep-size) {
        @include breakpoints(font-size, 24px, 24px, 30px);
        @include breakpoints(letter-spacing, 0.24px, 0.24px, 0.3px);
        @include breakpoints(line-height, 36px, 36px, 46px);
    }

    &:first-child:not(.skip-margin-top),
    &.first-child:not(.skip-margin-top) {
        transform: translateY(0px - (46px - 30px) / 2);
        margin-bottom: 0px - (46px - 30px) / 2;
    }

    &.uppercase,
    & .uppercase {
        text-transform: uppercase;
        letter-spacing: 1.35px;
    }

    &.extra-thin {
        @include breakpoints(letter-spacing, 0.24px, 0.24px, 0.15px);
    }
}

.keep-size {
    & .fs24 {
        @include breakpoints(font-size, 16px, 24px, 24px, !important);
        @include breakpoints(letter-spacing, 0.16px, 0.24px, 0.24px, !important);
        @include breakpoints(line-height, 26px, 36px, 36px, !important);
    }
}

.fs24 {
    @include breakpoints(font-size, 16px, 24px, 24px);
    @include breakpoints(letter-spacing, 0.16px, 0.24px, 0.24px);
    @include breakpoints(line-height, 26px, 36px, 36px);

    &:not(.keep-size) {
        @include breakpoints(font-size, 16px, 16px, 24px);
        @include breakpoints(letter-spacing, 0.16px, 0.16px, 0.24px);
        @include breakpoints(line-height, 26px, 26px, 36px);
    }

    &:first-child:not(.skip-margin-top),
    &.first-child:not(.skip-margin-top) {
        @include breakpoints-transform(translateY, 0px - (26px - 16px) / 2, 0px - (36px - 24px) / 2, 0px - (36px - 24px) / 2);
        @include breakpoints(margin-bottom, 0px - (26px - 16px) / 2, 0px - (36px - 24px) / 2, 0px - (36px - 24px) / 2);
    }

    &.uppercase,
    & .uppercase {
        text-transform: uppercase;
        letter-spacing: 1.08px;
    }
}

.fs16 {
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 19px;

    &:first-child:not(.skip-margin-top) {
        transform: translateY(0px - (26px - 16px) / 2);
        margin-bottom: 0px - (26px - 16px) / 2;
    }

    &.negativ-margin-bottom  {
        @include breakpoints(margin-bottom,0px - (26px - 16px) / 2, 0px - (26px - 16px) / 2, 0px - (26px - 16px) / 2);
    }
    
}

.fs12 {
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;

    // @include breakpoints(font-size, 10px, 10px, 12px);
    // @include breakpoints(letter-spacing, 0.10px, 0.10px, 0.12px);
    // @include breakpoints(line-height, 15px, 15px, 18px);

    &:first-child:not(.skip-margin-top) {
        transform: translateY(0px - (18px - 12px) / 2);
        margin-bottom: 0px - (18px - 12px) / 2;
    }

    &.negativ-margin-bottom  {
        @include breakpoints(margin-bottom,0px - (23px - 12px) / 2, 0px - (23px - 12px) / 2, 0px - (23px - 12px) / 2);
    }
    
}

@media (min-width: 768px) and (max-width: 1200px) {
    $minvw: 768px;
    $maxvw: 1920px;

    .text-content.fs30.extra-thin {
        @include fluid-property('font-size', 16px, 24px, $minvw, $maxvw);
        @include fluid-property('letter-spacing', 0.16px, 0.24px, $minvw, $maxvw);
        @include fluid-property('line-height', 26px, 36px, $minvw, $maxvw);
    }
  }