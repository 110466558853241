#blog-content {
  a,
  a:active,
  a:hover,
  a:focus {
    cursor: pointer !important;
    color: $blue-color;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px !important;
  }
  .input-content-blog-entries {
    @include breakpoints(margin-top, -4px, -4px, 11px);
    > .chapter-content:last-child {
      display: none !important;
    }
  }
  .chapter-content-blog {
    @include breakpoints(margin-top, -3px, -2px, 6px);
    @include breakpoints(margin-bottom, 7px, 10px, 18px);
    .chapter {
      .divider {
        background-color: $gray-color !important;
      }
    }
  }
  .inner-image-holder {
    @include breakpoints(margin-top, 8px, 9px, 26px);
  }
  .pagination-container {
    @include breakpoints(padding-top, 55px, 66px, 150px);
  }
  .content {
    .grid {
      grid-template:
        "hd main main" min-content
        "hd main main" min-content
        "aside main main" 1fr;
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .line-clamp-4 {
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
  .grid .media-bg {
    position: absolute;
    top: 0px;
    left: 0px;
    max-height: calc(100% + 40px);
    object-fit: cover;
    height: 100%;
  }
}

@media (max-width: 767px) {
  #blog-content {
    .content {
      .grid {
        grid-template:
          "hd main main main" min-content
          "hd main main main" min-content
          "hd main main main" min-content
          "aside main main main" 1fr !important;
        grid-template-columns: repeat(4, 1fr) !important;
      }
    }
  }
}
