@import "../mixins";

.block-fade_content {

    padding: $grid-gap !important;

    @media (max-width: 375px) {
        padding: $grid-gap / 2 !important;
    }

    & > div:first-child {
        overflow: visible !important;
    }

    & .w-100-20 {

        $mobile-width: calc(100% + $grid-gap / 2);
        $mobile-height: calc(100% + $grid-gap);

        @media (max-width: 375px) {
            width: $mobile-width;
            height: $mobile-height !important;

            margin-top: -$grid-gap / 2;
        }
    }
}

.fade-content {
    &.styled-scrollbar {
        overflow: hidden auto;
    }

    &::before {
        content: '';
        display: inline-block;
        position: absolute;

        height: $grid-gap - (36px - 24px) / 2;

        @media (max-width: 375px) {
            height: ($grid-gap / 2) - (26px - 16px) / 2;
        }

        width: calc(100% - ($grid-gap / 2));

        background-color: inherit;
        z-index: 1;
    }
    &::after {
        content: '';
        display: inline-block;
        position: absolute;

        bottom: 0px;

        height: $grid-gap;

        @media (max-width: 375px) {
            height: $grid-gap / 2;
        }

        width: calc(100% - ($grid-gap / 2));

        background-color: inherit;
    }

    & .tab-pane {

        &:first-child {
            z-index: 1;
            position: relative;

            padding-top: $grid-gap;

            @media (max-width: 375px) {
                padding-top: $grid-gap / 2;
            }
        }

        padding-top: $grid-gap - (36px - 24px) / 2;

        @media (max-width: 375px) {
            padding-top: ($grid-gap / 2) - (26px - 16px) / 2;
        }

        & h2 {
            font-weight: 400;
        }

        & p {
            & a {
                color: $third-color;
        
                &:active,
                &:focus,
                &:hover {
                    color: $third-color;
                }
            }
        }
    }

    & .grid {
        grid-template-rows: min-content min-content 1fr !important;
        grid-template-columns: 1fr !important;
        grid-auto-flow: column;

        grid-gap: 0px;

        & > .carousel-content-trigger {
            align-self: end;
        }
    }

    & .grid-content {

        grid-template-rows: max-content 1fr !important;

        grid-template-columns: 1fr !important;
        grid-auto-flow: column;

        grid-gap: 0px;
    }

    & .fade-trigger-container {
        align-self: end;

        padding-bottom: $grid-gap;

        @media (max-width: 375px) {
            padding-bottom: $grid-gap / 2;
        }
    }


    & .plus-icon,
    & .minus-icon {
        @include breakpoints(width, 25px, 35px, 60px);
        @include breakpoints(height, 25px, 35px, 60px);
    }

    & .fs40 {
        @media (min-width: 992px) and (max-width: 1473px) {
            @include fluid-property(font-size, 16px, 33px, 992px, 1473px);
            @include fluid-property(letter-spacing, 0.16px, 0.33px, 992px, 1473px);
            @include fluid-property(line-height, 26px, 50px, 992px, 1473px);
        }
    }
}
