@import "node_modules/bootstrap/scss/mixins/_transition";

.carousel {
    overflow: hidden;
}

.carousel-inner {
  overflow: visible !important;
}

.carousel-indicators {
  left: initial;
  top: -$grid-gap;
  right: -1px;
  bottom: -$grid-gap;

  margin: 0px;

  flex-direction: column;
  justify-content: start;
}

.carousel-indicators [data-bs-target] {
  display: none;

  flex-grow: 1;

  background: none;

  width: 5px;
  height: auto;

  margin-right: 0px;
  margin-left: 0px;

  border: 0px;

  cursor: inherit;

  &.active {
    background-color: $blau-1-color;
  }

  @media (min-width: 992px) {
    display: flex;
  }
}

.actions {
  .nav {
    position: absolute;
    bottom: 15px;
    left: 0px;
  }

  .carousel-back-controls {
    display: none;

    @media (min-width: 992px) {
      display: block;
    }
  }

  .carousel-controls-mobile {
    display: none;

    margin-bottom: 20px;

    @media (max-width: 991px) {
      display: block;
    }

    & .plus-icon {
      width: 35px;
      height: 35px;

      & .plus-icon-svg-color {
        stroke-width: 3px;
      }
    }
  }

  .carousel-controls {
    display: none;

    position: absolute;
    bottom: 40px;

    left: 50%;

    transform: translateX(-50%);

    @media (min-width: 992px) {
      display: flex;
    }
  }
}

.carousel-content {
  display: flex;
  flex-direction: column;
  height: 100%;

  padding: $grid-gap $grid-gap $grid-gap 0px;
}

.carousel-vertical {
  & .carousel-item {
    height: 100%;

    float: none;
    margin-right: 0px;

    margin-top: -100%;

    transform: translateY(100%);

    transition-property: transform;

    & ul {
      display: flex;
      flex-direction: column;
      padding-left: 0;
      margin-bottom: 7px;

      @extend .list-group;
    }

    & a {
      color: $third-color;

      &:active,
      &:focus,
      &:hover {
        color: $third-color;
      }
    }
  }

  & .active {
    &.carousel-item-end {
      transform: translateY(200%);
    }

    &.carousel-item-start {
      transform: translateY(-200%);
    }
  }

  & .carousel-item-next {
    &:not(.carousel-item-start) {
      transform: translateY(200%);
    }
  }

  & .carousel-item-prev {
    &:not(.carousel-item-end) {
      transform: translateY(-100%);
    }
  }
}
