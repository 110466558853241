@import "../mixins";

.tabs-content {
    
    & .tab-pane:first-child {
        padding: $grid-gap $grid-gap $grid-gap 0px;
    } 

    & .carousel-content-trigger {

        & .nav-link {
            padding: 0px;

            text-decoration: none;
        }
        
        & a {
            color: $third-color;

            &:active,
            &:focus,
            &:hover {
                @extend a;
            }
        }
    }

    & .scrollable-content {
        & .header {
            & .carousel-content-trigger {
                color: $third-color;
            }
        }
    }
}

.tab-content-offcanvas {
    & ul {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 5px;

        width: 100%;
        
        @extend .list-group;
    }

    & .contact_snippet_offcanvas {
        @include breakpoints(margin-top, 9px, 13px, 13px);
    }

    & .close-icon-container {
        top: $grid-gap;
        right: $grid-gap;

        & .offcanvas-close-icon {
            & .offcanvas-close-icon-svg-color {
                stroke: $blue-color;
            }
        }
    }
}