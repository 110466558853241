@import "../mixins";

@media (min-width: 768px) and (max-width: 1919px) {
    $minvw: 768px;
    $maxvw: 1920px;

    .logo {
        @include fluid-property('width', 55px, 225px, $minvw, $maxvw);
        @include fluid-property('height', 70.1px, 286.75px, $minvw, $maxvw);
    }

    .nav-link {
        font-weight: 700;
    }

    .nav-item {
        & .text-decoration-underline {
            @include fluid-property('text-underline-offset', 2px, 2px, $minvw, $maxvw);
            @include fluid-property('text-decoration-thickness', 1px, 2px, $minvw, $maxvw);
        }
    }

    #offcanvas-mobile-menu {
        & .navbar-nav {
            @include fluid-property(padding-top, 102px, 102px, $minvw, $maxvw);
        }
    
        & .nav-item {
            @include fluid-property(font-size, 40px, 40px, $minvw, $maxvw);
            @include fluid-property(letter-spacing, 0.2px, 0.2px, $minvw, $maxvw);
            @include fluid-property(line-height, 60px, 60px, $minvw, $maxvw);
    
            & .text-decoration-underline {
                @include fluid-property(text-underline-offset, 4px, 4px, $minvw, $maxvw);
                @include fluid-property(text-decoration-thickness, 1px, 2px, $minvw, $maxvw, '!important');
            }
        }
    
        & .search-nav-item {
            margin-top: 0px !important;

            & .nav-link {
                @include fluid-property(margin-top, 11px, 11px, $minvw, $maxvw);
            }
        }
    
        & .offcanvas-close-icon {
            & .offcanvas-close-icon-svg-color {
                @include fluid-property(stroke-width, 0.5px, 1.3px, $minvw, $maxvw);
            }
        }
    
        & .search-icon {
            @include fluid-property(width, 75px, 50px, $minvw, $maxvw);
            @include fluid-property(height, 75px, 50px, $minvw, $maxvw);
        
            & .search-icon-svg-color {
                @include fluid-property(stroke-width, 0.65px, 1.2px, $minvw, $maxvw);
            }
        }
    }

    .text-decoration-underline {
        @include fluid-property('text-underline-offset', 3px, 4px, $minvw, $maxvw);
        @include fluid-property('text-decoration-thickness', 1px, 2px, $minvw, $maxvw, '!important');
    }

    a {
        @include fluid-property('text-underline-offset', 3px, 4px, $minvw, $maxvw);
        @include fluid-property('text-decoration-thickness', 1px, 2px, $minvw, $maxvw, '!important');
        color: #7671ff;
    }

    .search-icon {
        @include fluid-property('width', 40px, 50px, $minvw, $maxvw);
        @include fluid-property('height', 40px, 50px, $minvw, $maxvw);

        & .search-icon-svg-color {
            @include fluid-property('stroke-width', 1.2px, 1.2px, $minvw, $maxvw);
        }
    }

    .search-nav-item {
        @include fluid-property('margin-top', 8px, 13px, $minvw, $maxvw);
    }

    .offcanvas-body {
        & .search-field {
            @include fluid-property(font-size, 40px, 40px, $minvw, $maxvw);
            @include fluid-property(letter-spacing, 0.2px, 0.2px, $minvw, $maxvw);
            @include fluid-property(line-height, 60px, 60px, $minvw, $maxvw);

            &::placeholder {
                @include fluid-property(font-size, 40px, 40px, $minvw, $maxvw);
                @include fluid-property(letter-spacing, 0.2px, 0.2px, $minvw, $maxvw);
                @include fluid-property(line-height, 60px, 60px, $minvw, $maxvw);
            }
        }
    }

    // font formats
    .s16-ls16-lh28 {
        @include fluid-property('font-size', 16px, 24px, $minvw, $maxvw);
        @include fluid-property('letter-spacing', 0.16px, 0.24px, $minvw, $maxvw);
        @include fluid-property('line-height', 28px, 38px, $minvw, $maxvw);
    }

    .social-icons-container {
        @include fluid-property(margin-top, 14px, 12px, $minvw, $maxvw);
    }

    .social-icon {
        @include fluid-property('width', 30px, 30px, $minvw, $maxvw);
        @include fluid-property('height', 30px, 30px, $minvw, $maxvw);
    }

    .menu-close-icon-svg-color,
    .hamburger-icon-svg-color {
        @include fluid-property(stroke-width, 1.5px, 2px, $minvw, $maxvw);
    }

    .scrollToTopContainer {    
        @include fluid-property('width', 65px, 100px, $minvw, $maxvw);
        @include fluid-property('height', 65px, 100px, $minvw, $maxvw);

        @include fluid-property('right', 30px, 40px, $minvw, $maxvw);
        @include fluid-property('bottom', 30px, 40px, $minvw, $maxvw);
    }

    .flow-text-padding-right {
        @include fluid-property('padding-right', 42.5px, 70px, $minvw, $maxvw);
    }

    footer {
        // @include fluid-property('padding-top', 100px, 200px, $minvw, $maxvw);

        & .footer-menu {
            & a {
                @include fluid-property('font-size', 16px, 24px, $minvw, $maxvw);
                @include fluid-property('letter-spacing', 0.16px, 0.24px, $minvw, $maxvw);
                @include fluid-property('line-height', 28px, 38px, $minvw, $maxvw);

                font-weight: 700;
            }
        }
    }
}