#contact-content {
  a,
  a:active,
  a:hover,
  a:focus {
    cursor: pointer !important;
    color: $blue-color;
    text-underline-offset: 3px;
    text-decoration-thickness: 2px !important;
  }
  .grid-middleblock {
    .input-content {
      margin-top: -20px;
    }
  }
}
