.scrollable-content {
    
    position: relative;

    & .header-placeholder {
        display: block;
        position: relative !important;
        top: 0px !important;

        visibility: hidden;
        transform: none !important;

        @extend .header;
    }

    & .line-clamp-2-mobil {
        @media (max-width: 767px) {
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box!important;
            overflow: hidden;
        }
    }

    & .w-100-10 {
        width: calc(100% + 10px) !important;
    }

    & .correct-width-in-scrollable {
        @include breakpoints(padding-left, 4px, 5px, 8px);
    }

    & .tabs-content {
        &::before {
            content: '';
            display: inline-block;
            position: absolute;

            height: $grid-gap;
            width: calc(100% - ($grid-gap / 2));

            background-color: inherit;
            z-index: 1;
        }
        &::after {
            content: '';
            display: inline-block;
            position: absolute;

            bottom: 0px;

            height: $grid-gap;
            width: calc(100% - ($grid-gap / 2));

            background-color: inherit;
        }
    }

    & .tab-pane {
        padding-top: $grid-gap;

        & ul {
            display: flex;
            flex-direction: column;
            padding-left: 0;
            margin-bottom: 7px;
      
            @extend .list-group;
        }

        & .plus-icon,
        & .minus-icon {
            @include breakpoints(width, 25px, 35px, 60px);
            @include breakpoints(height, 25px, 35px, 60px);
        }
    }

    & .grid {
        grid-template-rows: min-content min-content 1fr !important;
        grid-template-columns: 1fr !important;
        grid-auto-flow: column;

        grid-gap: 0px;

        & > .carousel-content-trigger {
            align-self: end;
        }
    }

    & .grid-content {

        grid-template-rows: max-content 1fr !important;

        grid-template-columns: 1fr !important;
        grid-auto-flow: column;

        grid-gap: 0px;

        & .close-nav {
            align-self: end;
            padding-bottom: $grid-gap;
        }
    }

    & .preview {
        flex-direction: column;
        height: 100%;

        @media (max-width: 767px) {
            height: auto;
        }
    }

    & .header {
        z-index: 2;
        width: calc(100% - ($grid-gap / 2));

        position: absolute;
        top: $grid-gap;

        background-color: inherit;
    }

    & .styled-scrollbar {
        overflow-y: auto;
    }
}